import React from 'react';
import './CylinderStats.css'; // Assurez-vous d'importer le CSS mis à jour

const CylinderStats = ({ stats }) => {
  return (
    <div className="cylinder-stats-container">
      <div className="cylinder-header">
        <h3>Tier Details</h3>
        <span>{`Past ${stats.spinHistory.length} Spins`}</span>
      </div>

      {/* Image de la roulette au-dessus */}
      <div className="roulette-wheel">
        <img src="http://193.70.3.153/wp-content/uploads/2024/09/roulette_2.png" alt="Roulette Wheel" />
      </div>

      <div className="cylinder-details">
        {/* Voisins du Zéro */}
        <div className="cylinder-row">
          <span className="segment-name">Neighbours of Zero</span>
          <div className="progress-bar-container">
            <div className="progress-bar green" style={{ width: `${(stats.voisinZero / stats.spinHistory.length) * 100}%` }}>
              {((stats.voisinZero / stats.spinHistory.length) * 100).toFixed(2)}%
            </div>
          </div>
        </div>

        {/* Orphelins */}
        <div className="cylinder-row">
          <span className="segment-name">Orphans</span>
          <div className="progress-bar-container">
            <div className="progress-bar red" style={{ width: `${(stats.orphelins / stats.spinHistory.length) * 100}%` }}>
              {((stats.orphelins / stats.spinHistory.length) * 100).toFixed(2)}%
            </div>
          </div>
        </div>

        {/* Tiers du Cylindre */}
        <div className="cylinder-row">
          <span className="segment-name">Third of the wheel</span>
          <div className="progress-bar-container">
            <div className="progress-bar orange" style={{ width: `${(stats.tiersCylindre / stats.spinHistory.length) * 100}%` }}>
              {((stats.tiersCylindre / stats.spinHistory.length) * 100).toFixed(2)}%
            </div>
          </div>
        </div>

        {/* Zero Game */}
        <div className="cylinder-row">
          <span className="segment-name">Zero Game</span>
          <div className="progress-bar-container">
            <div className="progress-bar purple" style={{ width: `${(stats.zeroGame / stats.spinHistory.length) * 100}%` }}>
              {((stats.zeroGame / stats.spinHistory.length) * 100).toFixed(2)}%
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CylinderStats;
