import React from 'react';
import './ColumnDetails.css'; // Assurez-vous que le fichier CSS est bien importé

const ColumnDetails = ({ stats }) => {
  const { columns, spinHistory } = stats;
  const totalSpins = spinHistory.length;

  // Fonction pour calculer les pourcentages
  const calculatePercentage = (count) => {
    return totalSpins === 0 ? '0%' : ((count / totalSpins) * 100).toFixed(2) + '%';
  };

  return (
    <div className="cylinder-stats-container">
      <div className="cylinder-header">
        <h3>Column Details</h3>
        <span>{`Past ${stats.spinHistory.length} Spins`}</span>
      </div>

      <div className="details-content">
        {/* Column 1 */}
        <div className="details-row">
          <span className="segment-name">1st Column</span>
          <div className="progress-bar-container">
            <div 
              className="progress-bar blue"
              style={{ width: calculatePercentage(columns.first) }}
            >
              {calculatePercentage(columns.first)}
            </div>
          </div>
        </div>

        {/* Column 2 */}
        <div className="details-row">
          <span className="segment-name">2nd Column</span>
          <div className="progress-bar-container">
            <div 
              className="progress-bar orange"
              style={{ width: calculatePercentage(columns.second) }}
            >
              {calculatePercentage(columns.second)}
            </div>
          </div>
        </div>

        {/* Column 3 */}
        <div className="details-row">
          <span className="segment-name">3rd Column</span>
          <div className="progress-bar-container">
            <div 
              className="progress-bar green"
              style={{ width: calculatePercentage(columns.third) }}
            >
              {calculatePercentage(columns.third)}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ColumnDetails;
