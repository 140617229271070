import React from 'react';
import './TimeFrameSelection.css';

const TimeFrameSelection = ({ timeFilter, setTimeFilter }) => {
  return (
    <div className="time-frame-selection-container">
      <button
        className={timeFilter === 1 ? 'active' : ''}
        onClick={() => setTimeFilter(1)}
      >
        Past 1 hour
      </button>
      <button
        className={timeFilter === 6 ? 'active' : ''}
        onClick={() => setTimeFilter(6)}
      >
        Past 6 hours
      </button>
      <button
        className={timeFilter === 12 ? 'active' : ''}
        onClick={() => setTimeFilter(12)}
      >
        Past 12 hours
      </button>
      <button
        className={timeFilter === 24 ? 'active' : ''}
        onClick={() => setTimeFilter(24)}
      >
        Past 24 hours
      </button>
      <button
        className={timeFilter === 48 ? 'active' : ''}
        onClick={() => setTimeFilter(48)}
      >
        Past 48 hours
      </button>
      <button
        className={timeFilter === 72 ? 'active' : ''}
        onClick={() => setTimeFilter(72)}
      >
        Past 72 hours
      </button>
      <button
        className={timeFilter === 168 ? 'active' : ''} // 168 heures dans une semaine
        onClick={() => setTimeFilter(168)}
      >
        Past Week
      </button>
      <button
        className={timeFilter === 720 ? 'active' : ''} // 720 heures approximativement dans un mois
        onClick={() => setTimeFilter(720)}
      >
        Past Month
      </button>
    </div>
  );
};

export default TimeFrameSelection;
