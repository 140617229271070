import React from 'react';
import './DozenPercentage.css'; // Assurez-vous que le fichier CSS est bien importé

const DozenPercentage = ({ stats }) => {
  const { dozens, spinHistory } = stats;
  const totalSpins = spinHistory.length;

  // Fonction pour calculer les pourcentages
  const calculatePercentage = (count) => {
    return totalSpins === 0 ? '0%' : ((count / totalSpins) * 100).toFixed(2) + '%';
  };

  return (
    <div className="cylinder-stats-container">
      <div className="cylinder-header">
        <h3>Row Details</h3>
        <span>{`Past ${stats.spinHistory.length} Spins`}</span>
      </div>

      <div className="details-content">
        {/* 1ère Douzaine */}
        <div className="details-row">
          <span className="segment-name">1st Dozen</span>
          <div className="progress-bar-container">
            <div 
              className="progress-bar blue"
              style={{ width: calculatePercentage(dozens.first) }}
            >
              {calculatePercentage(dozens.first)}
            </div>
          </div>
        </div>

        {/* 2ème Douzaine */}
        <div className="details-row">
          <span className="segment-name">2nd Dozen</span>
          <div className="progress-bar-container">
            <div 
              className="progress-bar orange"
              style={{ width: calculatePercentage(dozens.second) }}
            >
              {calculatePercentage(dozens.second)}
            </div>
          </div>
        </div>

        {/* 3ème Douzaine */}
        <div className="details-row">
          <span className="segment-name">3rd Dozen</span>
          <div className="progress-bar-container">
            <div 
              className="progress-bar green"
              style={{ width: calculatePercentage(dozens.third) }}
            >
              {calculatePercentage(dozens.third)}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DozenPercentage;
