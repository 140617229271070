import React from 'react';
import './NumberPercentage.css';

// Fonction pour obtenir la couleur du numéro de la roulette
const getNumberColor = (number) => {
  const redNumbers = [1, 3, 5, 7, 9, 12, 14, 16, 18, 19, 21, 23, 25, 27, 30, 32, 34, 36];
  const blackNumbers = [2, 4, 6, 8, 10, 11, 13, 15, 17, 20, 22, 24, 26, 28, 29, 31, 33, 35];

  if (number === 0) {
    return 'green';  // Couleur verte pour le zéro
  } else if (redNumbers.includes(number)) {
    return 'red';    // Couleur rouge pour les numéros rouges
  } else if (blackNumbers.includes(number)) {
    return 'black';  // Couleur noire pour les numéros noirs
  } else {
    return 'default'; // Couleur par défaut
  }
};

// Fonction pour calculer le nombre de spins depuis la dernière apparition du numéro
const calculateSince = (number, spinHistory) => {
  let spinsSinceLastAppearance = 0;
  
  // Parcourir la liste dans son ordre naturel (du plus ancien au plus récent)
  for (let i = 0; i < spinHistory.length; i++) {
    if (spinHistory[i].number === number) {
      return spinsSinceLastAppearance; // Retourner le nombre de spins écoulés
    }
    spinsSinceLastAppearance++; // Incrémenter si le numéro n'a pas encore été trouvé
  }
  
  // Si le numéro n'a jamais été trouvé
  return spinHistory.length;
};

const NumberPercentage = ({ stats, timePeriod }) => {
  const totalSpins = stats.spinHistory.length;

  // Fonction pour calculer le pourcentage
  const numberPercentage = (count) => (totalSpins === 0 ? 0 : ((count / totalSpins) * 100).toFixed(2));

  // Mapping pour rendre la période de temps lisible
  const timePeriodText = {
    1: "last hour",
    6: "last 6 hours",
    24: "last 24 hours",
  };

  return (
    <div className="number-percentage-container">
      <h3 className="number-title">Number Statistics</h3>
      <p className="number-subtitle">Statistics for the {timePeriodText[timePeriod]} - {totalSpins} Spins</p>

      <div className="number-grid">
        {Object.keys(stats.numbers).map((number) => (
          <div key={number} className="number-card">
            <div
              className={`number-box ${getNumberColor(Number(number))}`}
              style={{
                color: Number(number) === 0 ? 'white' : 'white', // Blanc pour 0 et les autres numéros
              }}
            >
              <span className="number-value">{number}</span>
            </div>

            {/* Infos sous la case */}
            <div className="number-info">
              <span className="number-percentage">
                {numberPercentage(stats.numbers[number].count)}%
              </span>
              <span className="number-count">
                ({stats.numbers[number].count}/{totalSpins})
              </span>
              <span className="number-since">
                {calculateSince(Number(number), stats.spinHistory)} Spins Since
              </span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default NumberPercentage;
