import React from 'react';
import './LastEightNumbers.css'; // Assurez-vous que le fichier CSS est bien importé

const LastEightNumbers = ({ stats }) => {
  const { lastEightNumbers } = stats;

  // Fonction pour déterminer la couleur d'un numéro (rouge, noir, vert)
  const getColorClass = (number) => {
	const redNumbers = [1, 3, 5, 7, 9, 12, 14, 16, 18, 19, 21, 23, 25, 27, 30, 32, 34, 36];
	const blackNumbers = [2, 4, 6, 8, 10, 11, 13, 15, 17, 20, 22, 24, 26, 28, 29, 31, 33, 35];

    if (number === 0) {
      return 'green'; // 0 est vert
    } else if (redNumbers.includes(number)) {
      return 'red'; // Numéros rouges
    } else if (blackNumbers.includes(number)) {
      return 'black'; // Numéros noirs
    }
    return ''; // Par défaut
  };

  return (
    <div className="last-eight-numbers-container">
      <div className="latest-spins-title">LATEST SPINS</div>
      <div className="last-eight-numbers">
        {lastEightNumbers.map((number, index) => (
          <div key={index} className={`number-box ${getColorClass(number)}`}>
            {number}
          </div>
        ))}
      </div>
    </div>
  );
};

export default LastEightNumbers;
