import React from 'react';
import './Temperature.css'; // Assurez-vous que ce fichier CSS est bien importé

const Temperature = ({ stats }) => {
  const { hotNumbers, coldNumbers, spinHistory, numbers } = stats;

  // Objet pour déterminer la couleur d'un numéro
  const getColorStyle = (number) => {
    const redNumbers = {
      1: true, 3: true, 5: true, 7: true, 9: true, 12: true, 14: true, 16: true, 18: true,
      19: true, 21: true, 23: true, 25: true, 27: true, 30: true, 32: true, 34: true, 36: true
    };
    const blackNumbers = {
      2: true, 4: true, 6: true, 8: true, 10: true, 11: true, 13: true, 15: true, 17: true,
      20: true, 22: true, 24: true, 26: true, 28: true, 29: true, 31: true, 33: true, 35: true
    };

    if (number === 0) {
      return { backgroundColor: 'green', color: 'white' }; // 0 est vert
    } else if (redNumbers[number]) {
      return { backgroundColor: 'red', color: 'white' }; // Numéros rouges
    } else if (blackNumbers[number]) {
      return { backgroundColor: 'black', color: 'white' }; // Numéros noirs
    }
    return {}; // Cas par défaut si le numéro n'appartient pas aux listes (devrait être impossible)
  };

  return (
    <div className="temperature-container">
      <div className="temperature-header">
        <h3>Temperature</h3>
        <span>{`past ${spinHistory.length} Spins`}</span>
      </div>

      <div className="temperature-section">
        <h4>Hot Numbers</h4>
        <div className="temperature-numbers">
          {hotNumbers.map((number, index) => (
            <div key={index} className="temperature-number-container">
              <div className="temperature-number" style={getColorStyle(number)}>
                {number}
              </div>
              <div className="temperature-percentage">
                {`${((numbers[number].count / spinHistory.length) * 100).toFixed(2)}%`}
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="temperature-section">
        <h4>Cold Numbers</h4>
        <div className="temperature-numbers">
          {coldNumbers.map((number, index) => (
            <div key={index} className="temperature-number-container">
              <div className="temperature-number" style={getColorStyle(number)}>
                {number}
              </div>
              <div className="temperature-percentage">
                {`${((numbers[number].count / spinHistory.length) * 100).toFixed(2)}%`}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Temperature;
