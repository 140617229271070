import React from 'react';
import './DetailsNumbers.css'; // Assurez-vous que le fichier CSS est bien importé

const DetailsNumbers = ({ stats }) => {
  const { pairImpair, range, spinHistory } = stats;
  const totalSpins = spinHistory.length;

  // Fonction pour calculer les pourcentages
  const calculatePercentage = (count) => {
    return totalSpins === 0 ? '0%' : ((count / totalSpins) * 100).toFixed(2) + '%';
  };

  return (
    <div className="cylinder-stats-container">
      <div className="cylinder-header">
        <h3>Number Details</h3>
        <span>{`Past ${stats.spinHistory.length} Spins`}</span>
      </div>

      <div className="details-content">
        {/* Pair/Impair */}
        <div className="details-row">
          <span className="segment-name">Even</span>
          <div className="progress-bar-container">
            <div 
              className="progress-bar blue"
              style={{ width: calculatePercentage(pairImpair.pair) }}
            >
              {calculatePercentage(pairImpair.pair)}
            </div>
          </div>
        </div>

        <div className="details-row">
          <span className="segment-name">Odd</span>
          <div className="progress-bar-container">
            <div 
              className="progress-bar orange"
              style={{ width: calculatePercentage(pairImpair.impair) }}
            >
              {calculatePercentage(pairImpair.impair)}
            </div>
          </div>
        </div>

        {/* Range 1-18 / 19-36 */}
        <div className="details-row">
          <span className="segment-name">1-18</span>
          <div className="progress-bar-container">
            <div 
              className="progress-bar green"
              style={{ width: calculatePercentage(range.low) }}
            >
              {calculatePercentage(range.low)}
            </div>
          </div>
        </div>

        <div className="details-row">
          <span className="segment-name">19-36</span>
          <div className="progress-bar-container">
            <div 
              className="progress-bar purple"
              style={{ width: calculatePercentage(range.high) }}
            >
              {calculatePercentage(range.high)}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailsNumbers;
