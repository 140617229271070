import React from 'react';
import './ColorDetails.css'; // Assurez-vous que le fichier CSS est bien importé

const ColorDetails = ({ stats }) => {
  const { spinHistory } = stats;

  // Compter les occurrences de chaque couleur
  const greenCount = spinHistory.filter(spin => spin.number === 0).length;
  const redNumbers = [1, 3, 5, 7, 9, 12, 14, 16, 18, 19, 21, 23, 25, 27, 30, 32, 34, 36];
  const redCount = spinHistory.filter(spin => redNumbers.includes(spin.number)).length;
  const blackCount = spinHistory.length - redCount - greenCount; // Calculer les noirs comme différence totale

  const totalSpins = spinHistory.length;

  // Fonction pour calculer les pourcentages
  const calculatePercentage = (count) => {
    return totalSpins === 0 ? '0%' : ((count / totalSpins) * 100).toFixed(2) + '%';
  };

  return (
    <div className="cylinder-stats-container">
      <div className="cylinder-header">
        <h3>Color Details</h3>
        <span>{`Past ${stats.spinHistory.length} Spins`}</span>
      </div>

      <div className="color-grid">
        {/* Green Case */}
        <div className="color-box green">
          <div className="percentage">{calculatePercentage(greenCount)}</div>
          <div className="label">Spins Were Green</div>
        </div>

        {/* Red Case */}
        <div className="color-box red">
          <div className="percentage">{calculatePercentage(redCount)}</div>
          <div className="label">Spins Were Red</div>
        </div>

        {/* Black Case */}
        <div className="color-box black">
          <div className="percentage">{calculatePercentage(blackCount)}</div>
          <div className="label">Spins Were Black</div>
        </div>
      </div>
    </div>
  );
};

export default ColorDetails;
