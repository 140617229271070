import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import moment from 'moment-timezone';
import { CSVLink } from 'react-csv';

import SpinHistory from './components/SpinHistory';
import CylinderStats from './components/CylinderStats';
import NumberPercentage from './components/NumberPercentage';
import Temperature from './components/Temperature';
import DozenPercentage from './components/DozenPercentage';
import DetailsNumbers from './components/DetailsNumbers';
import ColumnDetails from './components/ColumnDetails';
import LastEightNumbers from './components/LastEightNumbers';
import ColorDetails from './components/ColorDetails';
import TimeFrameSelection from './components/TimeFrameSelection'; 

import './App.css';

function App() {
  const [timeFilter, setTimeFilter] = useState(1); 
  const [spinHistoryLimit] = useState(30); 
  const [stats, setStats] = useState({
    numbers: {},
    spinHistory: [],
    lastEightNumbers: [],
    voisinZero: 0,
    orphelins: 0,
    tiersCylindre: 0,
    zeroGame: 0,
    dozens: { first: 0, second: 0, third: 0 },
    columns: { first: 0, second: 0, third: 0 },
    pairImpair: { pair: 0, impair: 0 },
    range: { low: 0, high: 0 },
    hotNumbers: [],
    coldNumbers: []
  });
  const [userTimeZone, setUserTimeZone] = useState(moment.tz.guess());
  const [currentPage, setCurrentPage] = useState(1);

  const file = document.getElementById('react-root').getAttribute('data-file') || 'Log';

  useEffect(() => {
    axios.get('https://ipapi.co/timezone/')
      .then(response => setUserTimeZone(response.data))
      .catch(() => setUserTimeZone(moment.tz.guess()));
  }, []);

  const fetchData = useCallback(async () => {
    try {
      const response = await axios.get(`https://casino-statistics.com/wp-json/custom/v1/data/${file}`);
      const filteredData = filterDataByTime(response.data, timeFilter); 
      const statistics = calculateStatistics(filteredData);
      setStats(statistics);
    } catch (error) {
      console.error('Erreur lors du chargement des données', error);
    }
  }, [file, timeFilter]);

  useEffect(() => {
    fetchData();
    const interval = setInterval(() => {
      fetchData(); 
    }, 5000);

    return () => clearInterval(interval);
  }, [fetchData]);

  const filterDataByTime = (data, hours) => {
    const currentTime = moment();
    return data.filter(row => {
      const spinTime = moment(row.Time);
      const timeDiff = currentTime.diff(spinTime, 'hours');
      return timeDiff <= hours;
    }).reverse(); 
  };

  const calculateStatistics = (data) => {
    const stats = {
      numbers: {},
      spinHistory: [],
      lastEightNumbers: [],
      voisinZero: 0,
      orphelins: 0,
      tiersCylindre: 0,
      zeroGame: 0,
      dozens: { first: 0, second: 0, third: 0 },
      columns: { first: 0, second: 0, third: 0 },
      pairImpair: { pair: 0, impair: 0 },
      range: { low: 0, high: 0 },
      hotNumbers: [],
      coldNumbers: []
    };

    const voisinZero = [22, 18, 29, 7, 28, 12, 35, 3, 26, 0, 32, 15, 19, 4, 21, 2, 25];
    const orphelins = [1, 20, 14, 31, 9, 17, 34, 6];
    const tiersCylindre = [33, 16, 24, 5, 10, 23, 8, 30, 11, 36, 13, 27];
    const zeroGame = [12, 35, 3, 26, 0, 32, 15];
    const firstColumn = [1, 4, 7, 10, 13, 16, 19, 22, 25, 28, 31, 34];
    const secondColumn = [2, 5, 8, 11, 14, 17, 20, 23, 26, 29, 32, 35];
    const thirdColumn = [3, 6, 9, 12, 15, 18, 21, 24, 27, 30, 33, 36];

    const lastEight = data.slice(0, 8);
    stats.lastEightNumbers = lastEight.map(spin => parseInt(spin.Number));

    data.forEach(row => {
      const number = parseInt(row.Number);
      const time = row.Time;

      stats.spinHistory.push({ number, time });

      stats.numbers[number] = stats.numbers[number] || { count: 0, percentage: 0 };
      stats.numbers[number].count++;

      if (voisinZero.includes(number)) {
        stats.voisinZero++;
      }
      if (orphelins.includes(number)) {
        stats.orphelins++;
      }
      if (tiersCylindre.includes(number)) {
        stats.tiersCylindre++;
      }
      if (zeroGame.includes(number)) {
        stats.zeroGame++;
      }

      if (number >= 1 && number <= 12) {
        stats.dozens.first++;
      } else if (number >= 13 && number <= 24) {
        stats.dozens.second++;
      } else if (number >= 25 && number <= 36) {
        stats.dozens.third++;
      }

      if (number % 2 === 0) {
        stats.pairImpair.pair++;
      } else {
        stats.pairImpair.impair++;
      }

      if (number >= 1 && number <= 18) {
        stats.range.low++;
      } else if (number >= 19 && number <= 36) {
        stats.range.high++;
      }

      if (firstColumn.includes(number)) {
        stats.columns.first++;
      } else if (secondColumn.includes(number)) {
        stats.columns.second++;
      } else if (thirdColumn.includes(number)) {
        stats.columns.third++;
      }
    });

    const totalSpins = data.length;
    for (let number in stats.numbers) {
      stats.numbers[number].percentage = ((stats.numbers[number].count / totalSpins) * 100).toFixed(2);
    }

    const sortedNumbers = Object.keys(stats.numbers).sort((a, b) => stats.numbers[b].count - stats.numbers[a].count);
    stats.hotNumbers = sortedNumbers.slice(0, 7); 
    stats.coldNumbers = sortedNumbers.slice(-7);

    return stats;
  };

  const csvData = stats.spinHistory.map(spin => ({
    Number: spin.number,
    Time: spin.time
  }));

  return (
    <div className="app-container">
      <div className="banner-latest-spins">
        <LastEightNumbers stats={stats} />
      </div>

      <div className="time-frame-selection">
        <TimeFrameSelection timeFilter={timeFilter} setTimeFilter={setTimeFilter} />
      </div>

      <div className="export-section">
        <CSVLink 
          data={csvData} 
          filename={`roulette_stats_${moment().format('YYYYMMDD_HHmmss')}.csv`}
          className="export-button"
          target="_blank"
        >
          Export CSV
        </CSVLink>
      </div>

      <div className="main-content">
        <div className="left-panel">
          <SpinHistory 
            stats={stats}
            spinHistoryLimit={spinHistoryLimit}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            userTimeZone={userTimeZone}
          />
        </div>

        <div className="right-panel">
          <Temperature stats={stats} />
          <CylinderStats stats={stats} />
          <ColorDetails stats={stats} />
          <DetailsNumbers stats={stats} />
          <DozenPercentage stats={stats} />
          <ColumnDetails stats={stats} />
        </div>
      </div>

      <div className="number-percentage">
        <NumberPercentage stats={stats} />
      </div>
    </div>
  );
}

export default App;
