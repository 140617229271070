import React from 'react';
import { useSpring, animated } from 'react-spring';
import moment from 'moment-timezone';
import './SpinHistory.css'; // Assurez-vous que le fichier CSS est bien importé

const SpinHistory = ({ stats, spinHistoryLimit, currentPage, setCurrentPage, userTimeZone }) => {
  const fadeIn = useSpring({ opacity: 1, from: { opacity: 0 } });

  // Pagination Calculations
  const totalPages = Math.ceil(stats.spinHistory.length / spinHistoryLimit);
  const start = (currentPage - 1) * spinHistoryLimit;
  const end = start + spinHistoryLimit;
  const paginatedHistory = stats.spinHistory.slice(start, end);

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  // Fonction pour déterminer la couleur d'un numéro
  const getColorClass = (number) => {
    const redNumbers = {
      1: true, 3: true, 5: true, 7: true, 9: true, 12: true, 14: true, 16: true, 18: true,
      19: true, 21: true, 23: true, 25: true, 27: true, 30: true, 32: true, 34: true, 36: true
    };
    const blackNumbers = {
      2: true, 4: true, 6: true, 8: true, 10: true, 11: true, 13: true, 15: true, 17: true,
      20: true, 22: true, 24: true, 26: true, 28: true, 29: true, 31: true, 33: true, 35: true
    };

    if (number === 0) {
      return 'green'; // 0 est vert
    } else if (redNumbers[number]) {
      return 'red'; // Numéros rouges
    } else if (blackNumbers[number]) {
      return 'black'; // Numéros noirs
    }
    return ''; // Si rien ne correspond (devrait être impossible)
  };

  return (
    <animated.div style={fadeIn}>
      <div className="spin-history-container">
        <div className="spin-history-header">
          <h3 className="spin-history-title">Spin History</h3>
          <span>{`past ${stats.spinHistory.length} Spins`}</span>
        </div>

        <table className="spin-history-table">
          <thead>
            <tr>
              <th>Finished</th>
              <th>Spin Result</th>
              <th>Sector (Tier)</th>
              <th>Dozen (1st/2nd/3rd)</th>
            </tr>
          </thead>
          <tbody>
            {paginatedHistory.map((spin, index) => {
              const number = spin.number;
              const time = moment(spin.time).tz(userTimeZone).format('DD MMM YYYY HH:mm');

              // Déterminer la partie du cylindre
              const voisinZero = [22, 18, 29, 7, 28, 12, 35, 3, 26, 0, 32, 15, 19, 4, 21, 2, 25];
              const orphelins = [1, 20, 14, 31, 9, 17, 34, 6];
              const tiersCylindre = [33, 16, 24, 5, 10, 23, 8, 30, 11, 36, 13, 27];

              let cylindrePart = '';
              if (voisinZero.includes(number)) {
                cylindrePart = 'Neighbours of Zero';
              } else if (orphelins.includes(number)) {
                cylindrePart = 'Orphans';
              } else if (tiersCylindre.includes(number)) {
                cylindrePart = 'Third of the wheel';
              }

              // Déterminer la douzaine
              let dozen = '';
              if (number >= 1 && number <= 12) {
                dozen = '1st Dozen';
              } else if (number >= 13 && number <= 24) {
                dozen = '2nd Dozen';
              } else if (number >= 25 && number <= 36) {
                dozen = '3rd Dozen';
              }

              return (
                <tr key={index}>
                  <td>{time}</td>
                  <td className={`spin-result ${getColorClass(number)}`}>
                    {number}
                  </td>
                  <td>{cylindrePart}</td>
                  <td>{dozen}</td>
                </tr>
              );
            })}
          </tbody>
        </table>

        {/* Pagination */}
        <div className="pagination">
          {currentPage > 1 && (
            <button onClick={handlePrevPage} className="pagination-button">
              Prev
            </button>
          )}
          <span>
            {start + 1}-{Math.min(end, stats.spinHistory.length)} of {stats.spinHistory.length}
          </span>
          {currentPage < totalPages && (
            <button onClick={handleNextPage} className="pagination-button">
              Next
            </button>
          )}
        </div>
      </div>
    </animated.div>
  );
};

export default SpinHistory;
